<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <span class="brand-logo">
          <b-img :src="appLogoImage" alt="logo" class="img_logo"/>
        </span>
      </b-link>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Register V2"/>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1">
              New Address
            </b-card-title>
  
            <!-- form -->
            <validation-observer ref="newregisterForm" #default="{ invalid }">
              <b-form @submit.prevent="submitNewRegister">
                <b-form-group label="Name">
                    <validation-provider #default="{ errors }" name="Name" :rules="requiredValidation">
                        <b-form-input id="name" placeholder="Name" v-model="newregisterForm.fullname" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Email">
                    <validation-provider #default="{ errors }" name="Email" rules="required|email">
                        <b-form-input id="email" placeholder="Email" v-model="newregisterForm.email" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Handphone">
                    <validation-provider #default="{ errors }" name="Handphone" rules="required|min:9">
                    <cleave id="mobile_phone" v-model="newregisterForm.mobile_phone" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid'" :raw="false" :options="options.mobile_phone" placeholder="Handphone"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Address">
                    <validation-provider #default="{ errors }" name="Address" :rules="requiredValidation">
                    <b-form-textarea id="address" placeholder="Address" v-model="newregisterForm.address" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Messages">
                    <validation-provider #default="{ errors }" name="Messages" :rules="requiredValidation">
                    <b-form-textarea id="messages" placeholder="Messages" v-model="newregisterForm.messages" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                  <vue-recaptcha
                    ref="invisibleRecaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    :sitekey="siteKey"
                    :loadRecaptchaScript="true"
                  ></vue-recaptcha>
                </b-form-group>
                <div slot="footer" class="text-right">
                    <b-button type="button" size="sm" variant="danger" @click="back"><i class="fa fa-undo"></i> Back</b-button>
                    &nbsp;
                    <b-button type="button" size="sm" variant="primary" @click="submitNewRegister" :disabled="invalid" ><i class="fa fa-save"></i> Save</b-button>
                  </div>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
    </b-row>
  </div>
</template>
  
  <script>
  import axios from "axios";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import VuexyLogo from "@core/layouts/components/Logo.vue";
  import { required, email } from "@validations";
  import interfaces from "@/templates/_interfaces.js";
  import { togglePasswordVisibility } from "@core/mixins/ui/forms";
  import Loading from "@core/loading/Loading.vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import store from '@/store/index'
  import { $themeConfig } from "@themeConfig";
  import { BLink, BButton, BImg } from 'bootstrap-vue'
  import VueRecaptcha from "vue-recaptcha";
  import Cleave from "vue-cleave-component";
  
  export default {
    components: {
      // BSV
      Loading,
      ValidationProvider,
      ValidationObserver,
      ToastificationContent,
      Cleave,
      VuexyLogo,
      BLink,
      BButton,
      BImg,
      VueRecaptcha
    },
    props: {
    requiredValidation: {
      type: String,
      default: "required",
    },
  },
  setup() {
      // App Name
      const { appName, appLogoImage } = $themeConfig.app;
      return {
        appName,
        appLogoImage,
      };
    },
    data() {
      return {
        siteKey: '6LcBoC4jAAAAAOiSKf0hR3G9RVowFtQw0EZOXUhG',
        isLoading: false,
        newregisterForm: interfaces.newregister,
        sideImg: require('@/assets/images/pages/register-v2.svg'),
        options: {
          mobile_phone: {
                blocks: ["13"],
                // prefix: '0',
                numericOnly: true,
            },
        }
      };
    },
    computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
    methods: {
      init: function () {},

      back: function () {
      this.$router.go(-1);
    },
    resetForm: function () {
      for (var i in interfaces.newregister) {
        interfaces.newregister[i] = null;
      }
      this.newregisterForm = interfaces.newregister;
    },
    onSubmit: function () {
      if (this.newregisterForm.captcha) {
        this.$refs.registerForm.validate().then((success) => {
          if (success) {
            useJwt
              .register({
                fullname: this.fullname,
                email: this.email,
                mobile_phone: this.mobile_phone,
                address: this.address,
              })
              .then((response) => {
                useJwt.setToken(response.data.accessToken);
                useJwt.setRefreshToken(response.data.refreshToken);
                localStorage.setItem("userData", JSON.stringify(response.data.userData));
                this.$ability.update(response.data.userData.ability);
                this.$router.push("/");
              })
              .catch((error) => {
                this.$refs.newregisterForm.setErrors(error.response.data.error);
              });
          }
        });
      } else {
        alert("Please verify you are not robot");
      }
    },
    onVerify: function (response) {
      this.newregisterForm.captcha = response;
    },
    onExpired: function () {
      alert("Your verification expired, please fill all information again");
      this.newregisterForm.captcha      = null;
      this.newregisterForm.fullname     = null;
      this.newregisterForm.email        = null;
      this.newregisterForm.mobile_phone = null;
      this.newregisterForm.address      = null;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    handleSubmit: function () {
      this.isLoading = false;
      this.$router.push({ path: "/new-addres-succes" });
    },
    submitNewRegister: function () {
      if(this.newregisterForm.captcha == null){
          alert("Invalid Captcha!");
          this.newregisterForm.captcha      = null;
          this.newregisterForm.fullname     = null;
          this.newregisterForm.email        = null;
          this.newregisterForm.mobile_phone = null;
          this.newregisterForm.address      = null;
        }else{
          this.$refs.newregisterForm.validate().then((success) => {
            if (success) {
                if (confirm("Are you sure want to Add this data ?")) {
                  this.isLoading = true;
                  axios
                    .post("self_registration/new_address/add", this.newregisterForm).then((response) => {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: "Form Add successful",
                          icon: "EditIcon",
                          variant: "success",
                          text: response.data.message,
                        },
                      });
                      this.handleSubmit();
                    })
                    .catch((error) => {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: "Form Add Failed",
                          icon: "EditIcon",
                          variant: "danger",
                          text: error.message,
                        },
                      });
                    })
                    .finally(() => {
                      this.isLoading = false;
                      this.resetForm();
                      this.init();
                      s;
                    });
                }
            }
          });
        } 
    },
    },
    mounted() {
      this.init();
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/pages/page-auth.scss";
  .text-center{
    padding-top: 10px;
  }
  // #card{
  //   margin-left: auto;
  //   margin-right: auto;
  // }
.img_logo{
  margin-top: -40px;
  margin-left: -20px;
}
.align-items-center{
  padding-top: 80px
}
  </style>
  