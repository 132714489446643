var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('span',{staticClass:"brand-logo"},[_c('b-img',{staticClass:"img_logo",attrs:{"src":_vm.appLogoImage,"alt":"logo"}})],1)]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" New Address ")]),_c('validation-observer',{ref:"newregisterForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitNewRegister.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Name","state":errors.length > 0 ? false : null},model:{value:(_vm.newregisterForm.fullname),callback:function ($$v) {_vm.$set(_vm.newregisterForm, "fullname", $$v)},expression:"newregisterForm.fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"Email","state":errors.length > 0 ? false : null},model:{value:(_vm.newregisterForm.email),callback:function ($$v) {_vm.$set(_vm.newregisterForm, "email", $$v)},expression:"newregisterForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Handphone"}},[_c('validation-provider',{attrs:{"name":"Handphone","rules":"required|min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{class:errors.length === 0 ? 'form-control' : 'form-control is-invalid',attrs:{"id":"mobile_phone","raw":false,"options":_vm.options.mobile_phone,"placeholder":"Handphone"},model:{value:(_vm.newregisterForm.mobile_phone),callback:function ($$v) {_vm.$set(_vm.newregisterForm, "mobile_phone", $$v)},expression:"newregisterForm.mobile_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"address","placeholder":"Address","state":errors.length > 0 ? false : null},model:{value:(_vm.newregisterForm.address),callback:function ($$v) {_vm.$set(_vm.newregisterForm, "address", $$v)},expression:"newregisterForm.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Messages"}},[_c('validation-provider',{attrs:{"name":"Messages","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"messages","placeholder":"Messages","state":errors.length > 0 ? false : null},model:{value:(_vm.newregisterForm.messages),callback:function ($$v) {_vm.$set(_vm.newregisterForm, "messages", $$v)},expression:"newregisterForm.messages"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('vue-recaptcha',{ref:"invisibleRecaptcha",attrs:{"sitekey":_vm.siteKey,"loadRecaptchaScript":true},on:{"verify":_vm.onVerify,"expired":_vm.onExpired}})],1),_c('div',{staticClass:"text-right",attrs:{"slot":"footer"},slot:"footer"},[_c('b-button',{attrs:{"type":"button","size":"sm","variant":"danger"},on:{"click":_vm.back}},[_c('i',{staticClass:"fa fa-undo"}),_vm._v(" Back")]),_vm._v("   "),_c('b-button',{attrs:{"type":"button","size":"sm","variant":"primary","disabled":invalid},on:{"click":_vm.submitNewRegister}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" Save")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }